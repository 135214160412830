import { useQuery } from "@tanstack/react-query";
import { Box, CircularProgress, Typography, Divider } from "@mui/material";
import { useState } from "react";
import { ActionButtonsFooter } from "../ActionButtonsFooter";
import { LineItemsSummary } from "./LineItemsSummary";
import { enqueueSnackbar } from "notistack";
import { SuccessfulSubmissionModal } from "./SuccessfulSubmissionModal";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";
import { Claim_ClaimsDbModel, FormQuestionDef_ClaimsDbModel, TypeSpecificAttachmentDef_ClaimsDbModel } from "@safelease/service-utilities";
import { useParams } from "react-router-dom";
import { penniesToDollars } from "../../../../utils/formatters";
import UploadedFileSummary from "./UploadedFileSummary";
import { SevereDelayModal } from "../SevereDelayModal";
import { UserQuestionsSummary } from "./UserQuestionsSummary/UserQuestionsSummary";
import { CountOutstandingDocuments } from "./CountOutstandingDocuments";
import { HorizontalClaimSummaryBar } from "./HorizontalClaimSummaryBar";

type SubmissionSummaryProps = {};

function SubmissionSummary({}: SubmissionSummaryProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const [continueRequestPending, setContinueRequestPending] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isSevereDelayModalOpen, setIsSevereDelayModalOpen] = useState(false);

  // pulls all claim info
  const claimQuery = useQuery<Claim_ClaimsDbModel>({
    queryKey: ["getClaim", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getClaimById(claimId);
      return response.data;
    },
  });

  // Pulls the typeSpecificAttachmentDefs for the claim
  const typeSpecificAttachmentDefsQuery = useQuery<TypeSpecificAttachmentDef_ClaimsDbModel[]>({
    queryKey: ["type-specific-attachment-defs", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getTypeSpecificAttachmentDefs(claimId);
      return response.data;
    },
    gcTime: 0,
  });

  // pulls the formQuestionDefs for the claim.
  const formQuestionDefsQuery = useQuery<FormQuestionDef_ClaimsDbModel[]>({
    queryKey: ["form-question-defs", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getFormQuestionDefs(claimId);
      return response.data;
    },
  });
  const formQuestionDefs = formQuestionDefsQuery.data || [];

  const handleContinueButtonClicked = async () => {
    const claimInfo = claimQuery.data;

    // checks to see if protectionPlanAddendumn or billingLedgerAddendum is present and not been deleted.
    const protectionPlanAddendumPresent = claimInfo?.protectionPlanAddendumDocs?.some((item) => item.attachment?.deletedAt === null);
    const billingLedgerAddendumPresent = claimInfo?.billingLedgerDocs?.some((item) => item.attachment?.deletedAt === null);

    // variables used to determine if the user has uploaded all the proof of ownership and purchase attachments
    const uploadedProofOfOwnership = claimInfo?.lineItems?.every(
      (item) => (item.proofOfOwnershipFiles?.filter((file) => !file.attachment?.deletedAt).length ?? 0) > 0,
    );
    const uploadedProofOfPurchase = claimInfo?.lineItems?.every(
      (item) => (item.proofOfPurchaseFiles?.filter((file) => !file.attachment?.deletedAt).length ?? 0) > 0,
    );
    const emptyProofFiles = uploadedProofOfOwnership && uploadedProofOfPurchase;

    // checks to see if the user has uploaded the type specific attachments needed for the claim type && if the attachment is needed for the claim type
    const typeSpecificAttachmentDefs = typeSpecificAttachmentDefsQuery.data;

    // Determine if type-specific attachments are needed
    const typeSpecificAttachmentsNeeded = typeSpecificAttachmentDefs && typeSpecificAttachmentDefs.length > 0;

    // Check if all type-specific attachment definitions have at least one file uploaded
    const allTypeSpecificAttachmentsPresent = typeSpecificAttachmentDefs?.every((def) =>
      def.typeSpecificAttachments?.some((file) => file.attachment && !file.attachment.deletedAt),
    );

    // Final condition to check if the requirements are met
    const typeSpecificAttachmentsConditionsMet =
      (typeSpecificAttachmentsNeeded && allTypeSpecificAttachmentsPresent) || !typeSpecificAttachmentsNeeded;

    // variables used to determine if the user has answered all questions
    const formQuestionDefs = formQuestionDefsQuery.data;
    const formQuestionDefsHaveUnansweredQuestions = formQuestionDefs?.every((item) => item.formQuestionResponses?.length ?? 0) ?? true;

    // checks to see if any are false
    const isSevereDelay = [
      protectionPlanAddendumPresent,
      billingLedgerAddendumPresent,
      typeSpecificAttachmentsConditionsMet,
      emptyProofFiles,
      formQuestionDefsHaveUnansweredQuestions,
    ].some((condition) => !condition);

    if (isSevereDelay) {
      setIsSevereDelayModalOpen(true);
      return;
    }
    setContinueRequestPending(true);
    try {
      await ClaimsApi.submitClaim(claimId);
      setModalOpen(true);
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again later.", {
        variant: "error",
      });
      // NOTE: Sometimes this error is thrown, but the claim is still submitted successfully
    } finally {
      setContinueRequestPending(false);
    }
  };

  const handleContinueAnyway = async () => {
    setIsSevereDelayModalOpen(false);
    try {
      await ClaimsApi.submitClaim(claimId);
      setModalOpen(true);
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again later.", {
        variant: "error",
      });
    } finally {
      setContinueRequestPending(true);
    }
  };

  if (claimQuery.error) {
    return <div>Something went wrong, please try again later.</div>;
  }
  // Variables for design
  const claimHeader = claimQuery.data?.claimType?.name + " Claim";

  return (
    <>
      <Typography variant="h5" sx={{ padding: "15px", paddingBottom: 0, mt: 1 }}>
        Review your claim before submitting
      </Typography>
      {/* Warning Header displaying count of outstanding documents and unanswered questions */}
      {claimQuery.data && (
        <CountOutstandingDocuments
          claimData={claimQuery.data}
          typeSpecificAttachmentDefs={typeSpecificAttachmentDefsQuery.data || []}
          formQuestionDefs={formQuestionDefsQuery.data || []}
        />
      )}
      <Box sx={{ padding: "15px" }}>
        {continueRequestPending || claimQuery.isLoading || typeSpecificAttachmentDefsQuery.isLoading || formQuestionDefsQuery.isLoading ? (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {claimHeader}
            </Typography>
            {/* Claim summary bar under Claim Type Header */}
            <HorizontalClaimSummaryBar
              unitNumber={claimQuery.data?.unit?.name}
              totalClaimedItemCount={claimQuery.data?.lineItems?.length}
              // acc is shorthand for accumulation. It is used to sum up the total amount of each item type or to set the amount to zero if undefined or null, then pennies to dollars function transforms into formatted string for display. If undefined/null or 0 then $0.00 is passed.
              totalRequestedAmount={penniesToDollars(
                claimQuery.data?.lineItems?.reduce((acc, item) => acc + (item.requestedAmount ?? 0), 0) ?? 0,
              )}
              lossDescription={claimQuery.data?.lossDescription ?? ""}
            />

            {/* Provided Documents & TypeSpecific Section */}
            <Box>
              {claimQuery.data && (
                <UploadedFileSummary claimData={claimQuery.data} typeSpecificAttachmentDefs={typeSpecificAttachmentDefsQuery.data || []} />
              )}
              <Divider sx={{ mt: 2 }} />
              <UserQuestionsSummary formQuestions={formQuestionDefs} />
              <Divider sx={{ mt: 2 }} />
            </Box>
            {/* Tenant Claimed Items Section */}
            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", mt: 2, mb: 2 }}>
              <Typography sx={{ fontSize: ".8rem", fontWeight: 600, mb: 1 }}>Claimed Items </Typography>
              <LineItemsSummary lineItems={claimQuery.data?.lineItems || []} />
            </Box>
          </>
        )}
      </Box>
      <ActionButtonsFooter label="Submit" onContinueButtonClick={handleContinueButtonClicked} disabled={continueRequestPending} />
      <SuccessfulSubmissionModal modalOpen={modalOpen} />
      <SevereDelayModal
        open={isSevereDelayModalOpen}
        onClose={() => setIsSevereDelayModalOpen(false)}
        onContinueAnyway={handleContinueAnyway}
      />
    </>
  );
}
export { SubmissionSummary };
