import { Navigate, Route, Routes } from "react-router-dom";
import { MainDashboardRouter } from "./MainDashboardRouter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PageNotFound from "./MainDashboardRouter/PageNotFound";
import { AuthenticationPage } from "./AuthenticationPage";
import { SPAuthenticationPage } from "./StorageProtectors/SPAuthenticationPage";
import { SPMainDashboardRouter } from "./StorageProtectors/SPMainDashboardRouter";
import { useEffect, useState } from "react";
import { UserAttributes, useAuthStore } from "./hooks/useAuthStore";
import { fetchUserAttributes, getCurrentUser, signOut } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { Box, LinearProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { mixpanelIdentify } from "./utils/mixpanel";
import { appConfig } from "./config/app.config";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const queryClient = new QueryClient();
  const setUserAttributes = useAuthStore((state) => state.setUserAttributes);
  const userAttributes = useAuthStore((state) => state.userAttributes);
  const devOverrideSubDomainIsSafeLease = appConfig.environment !== "development" ? undefined : true; // WARNING: IF THESE OVERRIDES ARE THE SAME VALUE, YOU WILL INFINITE LOOP IN LOCAL DEV
  const devOverrideUserIsThirdParty = appConfig.environment !== "development" ? undefined : false;
  const subDomain = window.location.hostname.split(".")[0].split("-")[0];
  const subDomainIsSafeLease = devOverrideSubDomainIsSafeLease ?? subDomain !== "storageprotectors";
  const userIsThirdParty = devOverrideUserIsThirdParty ?? userAttributes?.isThirdParty === "true";
  const safeleaseRedirectUrl =
    appConfig.environment === "production"
      ? "https://tenant.safelease.com"
      : appConfig.environment === "demo"
        ? "https://tenant-demo.safelease.com"
        : appConfig.environment === "staging"
          ? "https://tenant-staging.safelease.com"
          : "http://localhost:5174";

  const storageprotectorsRedirectUrl =
    appConfig.environment === "production"
      ? "https://storageprotectors.safelease.com"
      : appConfig.environment === "demo"
        ? "https://storageprotectors-demo.safelease.com"
        : appConfig.environment === "staging"
          ? "https://storageprotectors-staging.safelease.com"
          : "http://localhost:5174";

  useEffect(() => {
    const setAuthStatus = async () => {
      setIsLoading(true);
      try {
        const { username } = await getCurrentUser(); // throws an error if not authenticated
        setIsAuthenticated(!!username);
        const userAttributes = await fetchUserAttributes();
        const sanitizedUserAttributes: any = {};
        for (const [key, val] of Object.entries(userAttributes)) {
          // any custom attributes are prefixed with "custom:" so we remove that prefix
          sanitizedUserAttributes[key.startsWith("custom:") ? key.substring(7) : key] = val as string;
        }
        setUserAttributes(sanitizedUserAttributes as UserAttributes);
        Sentry.setUser({
          ...sanitizedUserAttributes,
          family_name: sanitizedUserAttributes?.family_name?.substring(0, 1) || undefined, // family name first initial
          phone_number: undefined,
        });
        mixpanelIdentify(sanitizedUserAttributes as UserAttributes);
      } catch (error: any) {
        if (error?.name === "PasswordResetRequiredException") {
          // TODO: if the user needs to reset their password, we should navigate them to the forgot password page
          enqueueSnackbar("Please reset your password.", { variant: "error" });
          signOut();
        }
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };
    const hubListenerCancelToken = Hub.listen("auth", (data) => {
      if (data.payload.event === "signedIn" || data.payload.event === "signedOut") {
        if (data.payload.event === "signedOut") {
          setIsLoading(true);
        }
        setAuthStatus();
      }
    });

    setAuthStatus();
    return () => {
      hubListenerCancelToken();
    };
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ width: "100vw", height: "100vh" }}>
        <LinearProgress />
      </Box>
    );
  }

  // Non-happy path for non-third party tenants who have somehow made it to the Storage Protectors subdomain
  if (isAuthenticated && !subDomainIsSafeLease && !userIsThirdParty) {
    // Excuse the .then(), we are at the top level
    signOut().then(() => {
      window.location.href = safeleaseRedirectUrl;
    });
    return null;
  }

  // Non-happy path for third party tenants who have somehow made it to the SafeLease tenant portal subdomain
  if (isAuthenticated && subDomainIsSafeLease && userIsThirdParty) {
    // Excuse the .then(), we are at the top level
    signOut().then(() => {
      window.location.href = storageprotectorsRedirectUrl;
    });
    return null;
  }

  // Happy path for Authed Storage Protectors
  if (isAuthenticated && !subDomainIsSafeLease && userIsThirdParty) {
    return (
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/*" element={<SPMainDashboardRouter />} />
        </Routes>
      </QueryClientProvider>
    );
  }

  // Happy path for Authed SafeLease tenant portal
  if (isAuthenticated && subDomainIsSafeLease && !userIsThirdParty) {
    return (
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/*" element={<MainDashboardRouter />} />
        </Routes>
      </QueryClientProvider>
    );
  }

  // Happy path for Unauthed Storage Protectors
  if (!subDomainIsSafeLease) {
    return (
      <Routes>
        <Route path="/" element={<SPAuthenticationPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  // Happy path for Unauthed SafeLease tenant portal
  return (
    <Routes>
      <Route path="/" element={<AuthenticationPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
