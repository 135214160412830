/**
 * Summary: This file is the main router for the StorageProtectors tenant portal.
 * It is used to render the home page, manage claims page, and the selected claim page.
 * The main difference is there are no private policy routes available.
 */
import { Box } from "@mui/material";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { SPHomePage, SPManageClaims, SPSelectedClaimPage } from "./";
import { NavbarWrapper } from "../MainDashboardRouter/NavbarWrapper.tsx";
import { useAuthStore } from "../hooks/useAuthStore.ts";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

type MainDashboardRouterProps = {};

function SPMainDashboardRouter({}: MainDashboardRouterProps) {
  const userAttributes = useAuthStore((state) => state.userAttributes);
  const location = useLocation();

  // If the user is debugging enabled, we start the Sentry replay if it is not already started
  useEffect(() => {
    const isDebuggingEnabled = userAttributes?.isDebuggingEnabled === "true";
    if (isDebuggingEnabled) {
      const replay = Sentry.getReplay();
      if (replay && !replay.getReplayId()) {
        replay.start();
      }
    }
  }, []);

  // Flush the Sentry replay buffer whenever the route changes
  useEffect(() => {
    const replay = Sentry.getReplay();
    if (replay) {
      replay.flush(); // Flushing the replay sends the captured data to Sentry
    }
  }, [location]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <NavbarWrapper>
              <SPHomePage />
            </NavbarWrapper>
          }
        />
        <Route
          path="/manage-claims"
          element={
            <NavbarWrapper>
              <SPManageClaims />
            </NavbarWrapper>
          }
        />
        <Route path="/claim/:claimId" element={<SPSelectedClaimPage />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Box>
  );
}

export { SPMainDashboardRouter };
