import { Box, Button, CircularProgress, LinearProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LineItem } from "./LineItem";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { putFileToS3PresignedUrl } from "../../../../api";
import { useClaimCreationStore } from "../useClaimCreationStore";
import { ActionButtonsFooter } from "../ActionButtonsFooter";
import { enqueueSnackbar } from "notistack";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";
import { HelpIcon } from "../HelpIcon";
import { useQuery } from "@tanstack/react-query";
import { Claim_ClaimsDbModel } from "@safelease/service-utilities";
import { LineItemFormData } from "./LineItemTypes";
import { penniesToDollars } from "../../../../utils/formatters";
import { useParams } from "react-router-dom";

type LineItemsListProps = {};

function LineItemsList({}: LineItemsListProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;

  const creationStep = useClaimCreationStore((state) => state.creationStep);
  const setCreationStep = useClaimCreationStore((state) => state.setCreationStep);
  const setBackButtonClicked = useClaimCreationStore((state) => state.setBackButtonClicked);
  const [continueRequestPending, setContinueRequestPending] = useState<boolean>(false);

  const claimQuery = useQuery<Claim_ClaimsDbModel>({
    queryKey: ["getClaim", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getClaimById(claimId);
      return response.data;
    },
  });

  const methods = useForm<LineItemFormData>({
    defaultValues: {
      lineItems: [
        {
          lineItemId: "",
          name: "",
          requestedAmount: "",
          make: "",
          model: "",
          serialNumber: "",
          proofOfOwnershipFiles: [],
          proofOfPurchaseFiles: [],
        },
      ],
    },
  });

  // add the ability to add or remove line items for the form
  const { fields, append, remove, replace } = useFieldArray({
    name: "lineItems",
    control: methods.control,
  });

  useEffect(() => {
    if (claimQuery.status === "success" && claimQuery.data?.lineItems && claimQuery.data.lineItems.length > 0) {
      replace(
        claimQuery.data.lineItems.map((lineItem) => {
          return {
            lineItemId: lineItem.id ?? "",
            name: lineItem.name ?? "",
            requestedAmount: penniesToDollars(lineItem.requestedAmount),
            make: lineItem.make ?? "",
            model: lineItem.model ?? "",
            serialNumber: lineItem.serialNumber ?? "",
            proofOfOwnershipFiles: (lineItem.proofOfOwnershipFiles ?? [])
              .filter((file) => file.attachment && !file.attachment.deletedAt)
              .map((file) => file.attachment!),
            proofOfPurchaseFiles: (lineItem.proofOfPurchaseFiles ?? [])
              .filter((file) => file.attachment && !file.attachment.deletedAt)
              .map((file) => file.attachment!),
          };
        }),
      );
    }
  }, [claimQuery.data]);

  const continueToNextStep = async (data: LineItemFormData) => {
    if (!claimId || !creationStep) {
      return;
    }
    setContinueRequestPending(true);
    try {
      for (const lineItem of data.lineItems) {
        const { proofOfOwnershipFiles, proofOfPurchaseFiles, lineItemId, ...stringData } = {
          ...lineItem,
        };
        // Step 1: Create or update the line item in the database with the string data
        const requestedAmountInPennies = Number(stringData.requestedAmount.replace(/\D/g, ""));
        const sanitizedRequestBody = {
          ...stringData,
          id: lineItemId,
          claimId,
          requestedAmount: requestedAmountInPennies,
        };
        const createLineItemResponse = await ClaimsApi.createOrUpdateLineItem(sanitizedRequestBody);
        const newOrUpdatedLineItem = createLineItemResponse.data;
        // Step 2: Create the new attachments for the proof files and Upload the files to s3 and create the ProofOfOwnershipFiles and ProofOfPurchaseFiles
        // Proof of ownership files
        for (const fileObj of proofOfOwnershipFiles) {
          if (!(fileObj instanceof File)) continue;
          const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(fileObj.name, fileObj.type);
          const { presignedUrl, attachment } = response.data;
          await putFileToS3PresignedUrl(fileObj, presignedUrl);
          await ClaimsApi.createProofOfOwnershipFile(newOrUpdatedLineItem.id, attachment.id);
        }
        // Proof of purchase files
        for (const fileObj of proofOfPurchaseFiles) {
          if (!(fileObj instanceof File)) continue;
          const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(fileObj.name, fileObj.type);
          const { presignedUrl, attachment } = response.data;
          await putFileToS3PresignedUrl(fileObj, presignedUrl);
          await ClaimsApi.createProofOfPurchaseFile(newOrUpdatedLineItem.id, attachment.id);
        }
        await ClaimsApi.updateClaim(claimId as string, {
          creationStep: creationStep + 1,
        });
      }
      setContinueRequestPending(false);
      setCreationStep(creationStep + 1);
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again later.", {
        variant: "error",
      });
      setContinueRequestPending(false);
    }
  };

  if (claimQuery.isLoading || !claimQuery.data) {
    return <LinearProgress />;
  }

  if (claimQuery.error) {
    return <div>Something went wrong, please try again later.</div>;
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(continueToNextStep)}
          style={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Stack
            spacing={2}
            sx={{
              paddingX: 3,
              paddingY: 1,
            }}
          >
            <Stack direction={"row"}>
              <Typography variant="h6">Include any lost or damaged items</Typography>
              <HelpIcon helpText="Individually List all items that were lost or damaged. If you have any receipts or proof of ownership for these items, please upload them below." />
            </Stack>
            <Typography sx={{ mb: 2, color: "grey.500", fontSize: ".9rem" }}>
              Please list all items that were lost or damaged. If you have any receipts or proof of ownership for these items, please upload
              them below.
            </Typography>
            <Box
              sx={{
                flex: 1,
                overflow: "auto",
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {continueRequestPending ? (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography>Uploading files.. this may take a minute. Please do not exit this page.</Typography>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Stack spacing={6}>
                    {fields.map((field, index) => (
                      <LineItem
                        key={field.id}
                        field={field}
                        remove={remove}
                        append={append}
                        index={index}
                        disableRemoveButton={fields.length === 1}
                      />
                    ))}
                  </Stack>
                  <Box>
                    <Button
                      variant="text"
                      disabled={continueRequestPending}
                      onClick={() =>
                        append({
                          lineItemId: "",
                          name: "",
                          requestedAmount: "",
                          make: "",
                          model: "",
                          serialNumber: "",
                          proofOfOwnershipFiles: [],
                          proofOfPurchaseFiles: [],
                        })
                      }
                    >
                      + Add item
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Stack>
          <ActionButtonsFooter
            type="submit"
            disabled={continueRequestPending}
            onBackButtonClick={() => {
              setBackButtonClicked(true);
              setCreationStep(creationStep! - 1);
            }}
          />
        </form>
      </FormProvider>
    </>
  );
}

export { LineItemsList };
