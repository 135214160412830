/**
 * Summary: This file is the selected claim page for StorageProtectors.
 * The main difference is it renders the SPClaimCreationForm instead of ClaimCreationForm.
 * See SPClaimCreationForm file for more details.
 */
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { Claim_ClaimsDbModel } from "@safelease/service-utilities";
import { ClaimStatus } from "../enums";
import { SPClaimCreationForm } from "./";
import { SubmittedClaimDetailsPage } from "../claim-portal/SelectedClaimPage/SubmittedClaimDetailsPage";
import { Box, CircularProgress } from "@mui/material";
import { useSubmittedClaimDetailPageStore } from "../claim-portal/SelectedClaimPage/SubmittedClaimDetailsPage/useSubmittedClaimDetailsPageStore";
import { useEffect } from "react";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance";
import { useTodoItemListStore } from "../claim-portal/SelectedClaimPage/SubmittedClaimDetailsPage/ToDoItemsContainer/ToDoItemList/useTodoItemListStore";
import { useClaimCreationStore } from "../claim-portal/SelectedClaimPage/ClaimCreationForm/useClaimCreationStore";

type SelectedClaimPageProps = {};

function SPSelectedClaimPage({}: SelectedClaimPageProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const { setTenantActions, setRefetchTenantActions } = useTodoItemListStore((state) => ({
    setTenantActions: state.setTenantActions,
    setRefetchTenantActions: state.setRefetchTenantActions,
  }));

  // Store functions for the submitted claim detail page store
  const { setClaim, setRefetchClaim, setIsActionNeeded } = useSubmittedClaimDetailPageStore((state) => ({
    setClaim: state.setClaim,
    setRefetchClaim: state.setRefetchClaim,
    setIsActionNeeded: state.setIsActionNeeded,
  }));

  // Store functions for the claim creation store
  const { setCreationStep, setClaimId, cleanUp } = useClaimCreationStore((state) => ({
    setCreationStep: state.setCreationStep,
    setClaimId: state.setClaimId, // TODO: remove this state variable from the store. We should be able to just utilize the useParams hook
    cleanUp: state.cleanUp,
  }));

  const claimQuery = useQuery<Claim_ClaimsDbModel>({
    queryKey: ["claim", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getClaimById(claimId);
      return response.data;
    },
    gcTime: 0, // do not cache this query
  });

  const tenantActionsQuery = useQuery({
    queryKey: ["getTenantActions", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getPendingTenantActionsForClaim(claimId);
      return response.data;
    },
  });

  // Initialize the refetch function and claim data for use in the SubmittedClaimDetailsPage component below.
  useEffect(() => {
    if (claimQuery.status === "success" && tenantActionsQuery.status === "success" && tenantActionsQuery.data) {
      setRefetchClaim(claimQuery.refetch);
      setClaim(claimQuery.data);
      setTenantActions(tenantActionsQuery.data);
      setRefetchTenantActions(tenantActionsQuery.refetch);
      setIsActionNeeded(claimQuery.data.status === ClaimStatus.TenantActionNeeded || tenantActionsQuery.data.length > 0);
      setCreationStep(claimQuery.data.creationStep);
      setClaimId(claimQuery.data.id);
    }
    return () => {
      cleanUp();
    };
  }, [
    claimQuery.status,
    setClaim,
    setRefetchClaim,
    claimQuery.refetch,
    setTenantActions,
    tenantActionsQuery.data,
    setRefetchTenantActions,
    tenantActionsQuery.status,
    setIsActionNeeded,
    setCreationStep,
    setClaimId,
  ]);

  if (claimQuery.isLoading || tenantActionsQuery.isLoading) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (claimQuery.isError || !claimQuery.data || tenantActionsQuery.isError || !tenantActionsQuery.data) {
    return <div>Error... please try again later</div>;
  }

  if (ClaimStatus.Incomplete === claimQuery.data?.status) {
    return <SPClaimCreationForm />;
  } else {
    return <SubmittedClaimDetailsPage />;
  }
}

export { SPSelectedClaimPage };
