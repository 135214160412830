export const appConfig = {
  aws: {
    cognitoUserPoolId: String(import.meta.env.VITE_COGNITO_USER_POOL_ID),
    cognitoAppClientId: String(import.meta.env.VITE_COGNITO_APP_CLIENT_ID),
  },
  claimsApiBaseUrl: String(import.meta.env.VITE_CLAIMS_API_BASE_URL || "http://localhost:8000"),
  authServiceApiBaseUrl: String(import.meta.env.VITE_AUTH_SERVICE_API_BASE_URL || "http://localhost:8001"),
  mixpanel: {
    token: import.meta.env.VITE_MIXPANEL_TOKEN,
    lowerEnvironmentEnabled: String(import.meta.env.VITE_MIXPANEL_LOWER_ENVIRONMENT_ENABLED || "false") === "true",
    devLoggerEnabled: true,
  },
  environment:
    window.location.hostname === "tenant.safelease.com" || window.location.hostname === "storageprotectors.safelease.com"
      ? "production"
      : window.location.hostname.endsWith("-demo.safelease.com")
        ? "demo"
        : window.location.hostname.endsWith("-staging.safelease.com")
          ? "staging"
          : "development",
};
