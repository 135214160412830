import { CircularProgress, Stack, Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { putFileToS3PresignedUrl } from "../../../../api";
import { useClaimCreationStore } from "../useClaimCreationStore";
import { TypeSpecificAttachmentFormElement } from "./TypeSpecificAttachmentFormElement";
import { ActionButtonsFooter } from "../ActionButtonsFooter";
import { enqueueSnackbar } from "notistack";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";
import { Attachment_ClaimsDbModel, TypeSpecificAttachmentDef_ClaimsDbModel } from "@safelease/service-utilities";

type TypeSpecificAttachmentsProps = {};

function TypeSpecificAttachments({}: TypeSpecificAttachmentsProps) {
  /**
   * backButtonClicked is used to determine if we should be going back or forward in the stepper if the current claim doesn't have any type specific attachments
   */
  const {
    claimId,
    creationStep,
    setCreationStep,
    setTypeSpecificAttachments,
    typeSpecificAttachments,
    backButtonClicked,
    setBackButtonClicked,
    clearTypeSpecificAttachments,
  } = useClaimCreationStore((state) => ({
    claimId: state.claimId,
    creationStep: state.creationStep,
    setCreationStep: state.setCreationStep,
    setTypeSpecificAttachments: state.setTypeSpecificAttachments,
    typeSpecificAttachments: state.typeSpecificAttachments,
    backButtonClicked: state.backButtonClicked,
    setBackButtonClicked: state.setBackButtonClicked,
    clearTypeSpecificAttachments: state.clearTypeSpecificAttachments,
  }));

  const [continueRequestPending, setContinueRequestPending] = useState<boolean>(false);

  const typeSpecificAttachmentDefsQuery = useQuery<TypeSpecificAttachmentDef_ClaimsDbModel[]>({
    queryKey: ["type-specific-attachment-defs", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getTypeSpecificAttachmentDefs(claimId as string);
      return response.data;
    },
    // do not cache this query, it will be called on every render
    gcTime: 0,
  });

  useEffect(() => {
    if (typeSpecificAttachmentDefsQuery.status === "success") {
      const tsaData = typeSpecificAttachmentDefsQuery.data;
      if (tsaData && !tsaData.length && creationStep) {
        setCreationStep(creationStep + (backButtonClicked ? -1 : 1));
        setBackButtonClicked(false);
      } else {
        tsaData?.forEach((typeSpecificAttachmentDef) => {
          setTypeSpecificAttachments(
            typeSpecificAttachmentDef.id,
            typeSpecificAttachmentDef.typeSpecificAttachments
              ?.filter((tsa) => tsa.attachment)
              .map((tsa) => tsa.attachment) as Attachment_ClaimsDbModel[],
          );
        });
        if (backButtonClicked) {
          setBackButtonClicked(false);
        }
      }
    }
    return () => {
      clearTypeSpecificAttachments();
    };
  }, [
    typeSpecificAttachmentDefsQuery.status,
    typeSpecificAttachmentDefsQuery.data,
    setTypeSpecificAttachments,
    creationStep,
    setCreationStep,
    backButtonClicked,
    setBackButtonClicked,
    clearTypeSpecificAttachments,
  ]);

  const continueToNextStep = async () => {
    if (creationStep === null || !claimId) {
      return;
    }
    setContinueRequestPending(true);
    try {
      for (const [typeSpecificAttachmentDefId, files] of Object.entries(typeSpecificAttachments)) {
        if (files) {
          for (const file of files) {
            if (file instanceof File) {
              const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(file.name, file.type);
              const { presignedUrl, attachment } = response.data;
              await putFileToS3PresignedUrl(file, presignedUrl);
              await ClaimsApi.createTypeSpecificAttachment({
                claimId: claimId,
                typeSpecificAttachmentDefId: typeSpecificAttachmentDefId,
                attachmentId: attachment.id,
              });
            }
          }
        }
      }
      await ClaimsApi.updateClaim(claimId as string, {
        creationStep: creationStep + 1,
      });
      setContinueRequestPending(false);
      setCreationStep(creationStep + 1);
    } catch (err) {
      enqueueSnackbar("Something went wrong, please try again later.", {
        variant: "error",
      });
      setContinueRequestPending(false);
    }
  };

  if (typeSpecificAttachmentDefsQuery.isLoading || typeSpecificAttachmentDefsQuery.isFetching) {
    return <LinearProgress />;
  }

  return (
    <>
      <Stack>
        {continueRequestPending || typeSpecificAttachmentDefsQuery.isLoading ? (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Stack>
            {typeSpecificAttachmentDefsQuery.data?.map((attachmentDef) => (
              <TypeSpecificAttachmentFormElement
                key={attachmentDef.id}
                title={attachmentDef.label}
                subTitle={attachmentDef.description}
                typeSpecificAttachmentDefId={attachmentDef.id}
              />
            ))}
          </Stack>
        )}
      </Stack>
      <ActionButtonsFooter
        onContinueButtonClick={continueToNextStep}
        disabled={continueRequestPending}
        backButtonDisabled={continueRequestPending}
      />
    </>
  );
}
export { TypeSpecificAttachments };
