import { Button, CircularProgress, Typography, useMediaQuery, useTheme, Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useClaimCreationStore } from "./useClaimCreationStore";
import { ProtectionPlanAddendumUpload } from "./ProtectionPlanAddendumUpload";
import { BillingLedgerUpload } from "./BillingLedgerUpload";
import { EligibilityCheck } from "./EligibilityCheck";
import { VerticalLinearStepper } from "./VerticalLinearStepper";
import { UserQuestions } from "./UserQuestions/UserQuestions";
import { TypeSpecificAttachments } from "./TypeSpecificAttachments";
import { ClaimDescription } from "./ClaimDescription";
import { LineItemsList } from "./LineItemsList";
import { SubmissionSummary } from "./SubmissionSummary";
import { AdjusterAvatarHeader } from "./AdjusterAvatarHeader";
import BackgroundBoxes from "../../../assets/bottomRightBackgroundImage.png";

const ClaimCreationForm = () => {
  const navigate = useNavigate();
  const activeStep = useClaimCreationStore((state) => state.activeStep);
  const creationStep = useClaimCreationStore((state) => state.creationStep);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const creationFormHelpText = (
    <>
      Need help filing your claim? Contact support <a href="mailto:support@safelease.com">here</a>
    </>
  );

  if (creationStep === null) {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          minHeight: 500,
          flex: 1,
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${BackgroundBoxes})`,
            backgroundColor: "#152744",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            padding: "30px",
            width: isMobile ? "100%" : 250,
            height: isMobile ? "25%" : "100%",
            minHeight: 175,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: "30px",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: 400, fontSize: "1.25rem" }}>
              {[0, 1].includes(creationStep) ? "Eligibility Check" : creationStep === 7 ? "Finish" : "Claim form"}
            </Typography>

            <Button
              sx={
                isMobile
                  ? { color: "white", fontSize: ".8rem", width: "50%", justifyContent: "flex-end", fontWeight: 400 }
                  : { display: "none" }
              }
              onClick={() => navigate("/manage-claims")}
            >
              Save & Close
            </Button>
          </Box>

          <VerticalLinearStepper activeStep={activeStep} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: isMobile ? "visible" : "auto",
          }}
        >
          <Stack direction="row" justifyContent="space-between" paddingY={"1rem"}>
            <AdjusterAvatarHeader helpText={creationFormHelpText} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                sx={isMobile ? { display: "none" } : { color: "black", fontSize: 14, mr: 2 }}
                onClick={() => navigate("/manage-claims")}
              >
                Save & Close
              </Button>
            </Box>
          </Stack>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              width: "100%",
              overflow: isMobile ? "visible" : "auto",
            }}
          >
            <>
              {creationStep === 0 && <EligibilityCheck />}
              {creationStep === 1 && <ProtectionPlanAddendumUpload />}
              {creationStep === 2 && <BillingLedgerUpload />}
              {creationStep === 3 && <ClaimDescription />}
              {creationStep === 4 && <UserQuestions />}
              {creationStep === 5 && <TypeSpecificAttachments />}
              {creationStep === 6 && <LineItemsList />}
              {creationStep === 7 && <SubmissionSummary />}
            </>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { ClaimCreationForm };
