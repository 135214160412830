/**
 * Summary: This file is the manage claims page for StorageProtectors.
 * The main difference is it renders the SPUnitSelectionModal instead of the regular UnitSelectionModal.
 * See SPUnitSelectionModal file for more details.
 */
import { Box, CircularProgress, Stack, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { SafeLeaseBreadcrumbs, SafeLeaseButton } from "@safelease/components";
import AddIcon from "@mui/icons-material/Add";
import TwoStackedFiles from "../assets/TwoStackedFiles.svg";
import { ClaimList } from "../claim-portal/ManageClaims/ClaimList";
import { SPUnitSelectionModal } from "./";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance";
import { useAuthStore } from "../hooks/useAuthStore";
import { TenantClaimListItem } from "@safelease/service-utilities";

function SPManageClaims({}) {
  const navigate = useNavigate();
  const [unitSelectionModalOpen, setUnitSelectionModalOpen] = useState(false);
  const userAttributes = useAuthStore((state) => state.userAttributes);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const activeClaimsQuery = useQuery({
    queryKey: ["active-claims", userAttributes?.sub],
    queryFn: async () => {
      const response = await ClaimsApi.getActiveClaimsForUser();
      return response.data;
    },
  });
  const closedClaimsQuery = useQuery({
    queryKey: ["closed-claims", userAttributes?.sub],
    queryFn: async () => {
      const response = await ClaimsApi.getClosedClaimsForUser();
      return response.data;
    },
  });

  const hasSubmittedClaim =
    (activeClaimsQuery.data && activeClaimsQuery.data?.length > 0) || (closedClaimsQuery.data && closedClaimsQuery.data.length > 0);

  return (
    <Stack flex={1} overflow="auto">
      <Stack sx={{ flexDirection: "row" }} justifyContent="space-between" alignItems="center">
        <SafeLeaseBreadcrumbs
          navigate={navigate}
          path={[
            { part: "Home", href: "/" },
            { part: "My claim", href: "/manage-claims" },
          ]}
        />
        {hasSubmittedClaim && (
          <SafeLeaseButton
            onClick={() => setUnitSelectionModalOpen(true)}
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{
              color: "navy.main",
              border: "none",
              "&:hover": {
                border: "none",
              },
            }}
          >
            New claim
          </SafeLeaseButton>
        )}
      </Stack>
      <MainContent
        activeClaimsQuery={activeClaimsQuery}
        closedClaimsQuery={closedClaimsQuery}
        setUnitSelectionModalOpen={setUnitSelectionModalOpen}
        isMobile={isMobile}
        theme={theme}
      />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            fontSize: isMobile ? 12 : 14,
            fontWeight: 500,
            color: (theme: Theme) => theme.palette.grey.A200,
          }}
        >
          Have any questions or concerns about a claim? Email us at: <span style={{ color: "black" }}>claims@safelease.com</span>
        </Typography>
      </Box>
      {unitSelectionModalOpen && <SPUnitSelectionModal setModalOpen={setUnitSelectionModalOpen} />}
    </Stack>
  );
}

const MainContent = ({
  activeClaimsQuery,
  closedClaimsQuery,
  setUnitSelectionModalOpen,
  isMobile,
}: {
  activeClaimsQuery: UseQueryResult<TenantClaimListItem[]>;
  closedClaimsQuery: UseQueryResult<TenantClaimListItem[]>;
  setUnitSelectionModalOpen: (open: boolean) => void;
  isMobile: boolean;
  theme: Theme;
}) => {
  const queriesLoading = activeClaimsQuery.isLoading || closedClaimsQuery.isLoading;
  const queriesHadError = activeClaimsQuery.isError || closedClaimsQuery.isError;
  const noPriorClaimsToDisplay = activeClaimsQuery.data?.length === 0 && closedClaimsQuery.data?.length === 0;
  if (queriesLoading) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (queriesHadError) {
    return <div>We have encountered an error. Please try reloading the page.</div>;
  }

  if (noPriorClaimsToDisplay) {
    return (
      <Stack spacing={2} flex={1} alignItems="center" justifyContent="center">
        <img style={{ width: isMobile ? "80px" : "120px" }} src={TwoStackedFiles} alt="two stacked files icon" />
        <Typography sx={{ fontSize: isMobile ? 16 : 20, fontWeight: 500 }}>No claim</Typography>
        <SafeLeaseButton
          onClick={() => setUnitSelectionModalOpen(true)}
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{
            color: "navy.main",
            border: "none",
            "&:hover": {
              border: "none",
            },
          }}
        >
          New claim
        </SafeLeaseButton>
      </Stack>
    );
  }
  return (
    <Stack sx={{ paddingTop: 2, flex: 1 }} spacing={3}>
      {activeClaimsQuery.data && activeClaimsQuery.data.length > 0 && (
        <Stack>
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Active claims</Typography>
          <ClaimList claims={activeClaimsQuery.data} />
        </Stack>
      )}
      {closedClaimsQuery.data && closedClaimsQuery.data.length > 0 && (
        <Stack>
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Closed claims</Typography>
          <ClaimList claims={closedClaimsQuery.data} />
        </Stack>
      )}
    </Stack>
  );
};

export { SPManageClaims };
