/**
 * Summary: This file varies from the HomePage in that it is only used for StorageProtectors.
 * The main difference is the copy, and there is no private policy functionality.
 */
import { Grid, Stack, Theme, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { SafeLeaseCard } from "@safelease/components";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useAuthStore } from "../hooks/useAuthStore.ts";

function SPHomePage() {
  const userAttributes = useAuthStore((state) => state.userAttributes);
  const navigate = useNavigate();

  const claimsClicked = () => {
    navigate("/manage-claims");
  };

  const userActionCardStyles = {
    height: 259,
    display: "flex",
    boxShadow: 0,
  };

  return (
    <Grid container spacing={2} padding="30px">
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Welcome, {userAttributes?.given_name}
        </Typography>
        <Typography>Get started with quick actions below</Typography>
      </Grid>
      <Grid item xs={12}>
        <SafeLeaseCard clickable sx={userActionCardStyles} onClick={claimsClicked}>
          <Stack flex={1} justifyContent="center" alignItems="center">
            <ArticleOutlinedIcon sx={{ fontSize: 32, color: "blue.main" }} />
            <Typography sx={{ fontSize: 18, fontWeight: 500 }} textAlign="center">
              My claim
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 12,
                color: (theme: Theme) => theme.palette.grey.A200,
              }}
              textAlign="center"
            >
              Submit and manage claim
            </Typography>
          </Stack>
        </SafeLeaseCard>
      </Grid>
    </Grid>
  );
}

export { SPHomePage };
